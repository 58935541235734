exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-who-are-we-js": () => import("./../../../src/pages/who-are-we.js" /* webpackChunkName: "component---src-pages-who-are-we-js" */),
  "component---src-pages-why-edtech-sector-js": () => import("./../../../src/pages/why-edtech-sector.js" /* webpackChunkName: "component---src-pages-why-edtech-sector-js" */),
  "component---src-templates-regulation-regulation-jsx": () => import("./../../../src/templates/regulation/regulation.jsx" /* webpackChunkName: "component---src-templates-regulation-regulation-jsx" */)
}

